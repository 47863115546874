import React, { memo } from 'react'
import { Dayjs } from 'dayjs'
import dayAPI from '~/utils/dayAPI'
import useMedia from '~/hooks/useMedia'
import { TextField, TextFieldProps } from '@mui/material'
import { DesktopDateTimePicker, MobileDateTimePicker } from '@mui/x-date-pickers'

export const DateTimePicker = memo<
  React.PropsWithChildren<{
    label: string
    value?: string
    onChangeDelegate(value: Dayjs): void
    disabled?: boolean
  }>
>(function TimeSelect(props) {
  const { isPc } = useMedia()
  const defaultProps = {
    reduceAnimations: true,
    ampm: false,
    hideTabs: false,
    value: props.value,
    disabled: props.disabled,
    onChange: (value: string | null | undefined) => {
      props.onChangeDelegate(dayAPI(value))
    },
    mask: '____/__/__ __:__',
    label: props.label,
    renderInput: (params: TextFieldProps) => (
      <TextField
        size='small'
        {...params}
      />
    ),
  }
  return isPc ? (
    <DesktopDateTimePicker {...defaultProps} />
  ) : (
    <MobileDateTimePicker {...defaultProps} />
  )
})
