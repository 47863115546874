import { memo } from 'react'
import { useOptionsSourceState } from '~/modules/options/shareContainers/useOptionsSourceState'
import { FormControl, FormControlLabel, Checkbox } from '@mui/material'
import { useOptionDaysState } from '../shareContainers/useOptionDaysState'

export const BackTestToggle = memo(function BackTestToggle(props) {
  const { state, acts } = useOptionsSourceState.useContainer()
  const { state: dayState } = useOptionDaysState.useContainer()
  return (
    <FormControl>
      <FormControlLabel
        onChange={(e, checked) => acts.setIsBackTest(checked)}
        value={state.isBackTest}
        control={
          <Checkbox
            color='primary'
            checked={state.isBackTest}
          />
        }
        disabled={dayState.slidingWindowMode}
        label='價格回溯'
      />
    </FormControl>
  )
})
