import { css } from '@emotion/react'
import { FormControl, InputLabel, MenuItem, Select, outlinedInputClasses } from '@mui/material'
import { Dayjs } from 'dayjs'
import React, { memo } from 'react'
import useMedia from '~/hooks/useMedia'
import dayAPI from '~/utils/dayAPI'

export const DaySelect = memo<
  React.PropsWithChildren<{
    label: string
    value?: Dayjs | undefined
    disabled?: boolean
    onChange(value: Dayjs): void
    days: Dayjs[]
  }>
>(function DaySelect(props) {
  const { isPhone } = useMedia()
  return (
    <FormControl
      variant='outlined'
      disabled={props.disabled}
      css={css`
        width: ${isPhone ? 160 : 192}px;
        .${outlinedInputClasses.input} {
          padding: 12px 8px;
          ${isPhone && 'letter-spacing: -1px;'}
        }
      `}
    >
      <InputLabel>{props.label}</InputLabel>
      <Select
        value={props.value?.toISOString() ?? ''}
        onChange={e => {
          props.onChange(dayAPI(e.target.value as string))
        }}
        label={props.label}
      >
        {props.days.map(day => {
          const isToday = day.format('MMDD') === dayAPI().format('MMDD')
          return (
            <MenuItem
              key={day.toString()}
              value={day.toISOString()}
            >
              <div>
                {day.format('MMDD (dd) HH:mm')}
                {isToday && ' 今天'}
              </div>
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
})
