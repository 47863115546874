import { css, useTheme } from '@emotion/react'
import React, { memo } from 'react'
import { useMount, useUnmount } from 'react-use'
import useMedia from '~/hooks/useMedia'
import { getQuoteTextColor } from '~/modules/AppLayout/colorUtils'
import { Socket } from '~/modules/SDK/socket2/Socket'
import { flex } from '~/modules/AppLayout/FlexGridCss'
import { processQuoteToReadable } from '~/utils/processQuoteToReadable'
import { useSignalrStore } from '~/modules/SDK/Signalr/useSignalrStore'
import { SymbolName } from '~/modules/SDK/Symbol/SymbolName'
import { useQuoteUpdateAnimationCSS } from '~/modules/SDK/socket/useQuoteUpdateAnimationCSS'
import { useSignalrStoreValueOHLC } from '~/modules/SDK/Signalr/useSignalrStoreValueOHLC'
import { Fade } from '@mui/material'
import { ToolTipSmallChartAi } from '~/modules/investment-consultant/symbolTrendChart/SymbolTrendChart/ToolTipSmallChart'
import { fontWeight600 } from '~/css/font'
import { Tooltip } from '@mantine/core'
import { useThemeOfParent } from '~/components/theme/useThemeOfParent'

export const SignalrOptionRefQuote = memo<
  ReactProps<{
    symbol: Socket.SymbolString
  }>
>(function SignalrOptionRefQuote(props) {
  const { isPc } = useMedia()
  const symbolInfo = useSignalrStoreValueOHLC(state => state.value[props.symbol])
  const quoteChanges = processQuoteToReadable(symbolInfo as Socket.ValueOfOHLC)
  const quoteAnimation = useQuoteUpdateAnimationCSS(symbolInfo?.close)
  const parentTheme = useThemeOfParent()
  const isDark = parentTheme.isDark
  useMount(() => {
    useSignalrStore.getState().subscribeAdd([props.symbol], 'ohlc')
  })

  useUnmount(() => {
    useSignalrStore.getState().subscribeRemove([props.symbol], 'ohlc')
  })

  return (
    <Tooltip
      position='bottom'
      color='#55555a'
      label={
        <ToolTipSmallChartAi
          symbol={props.symbol}
          isDark={isDark}
        />
      }
    >
      <div
        css={css`
          ${optionRefQuoteBoxCss}
          padding: ${isPc ? '8px 16px' : '8px'};
          border: ${getQuoteTextColor(quoteChanges.closeChangePercent, isDark)} 1px solid;
          #__next & {
            color: ${getQuoteTextColor(quoteChanges.closeChangePercent, isDark)};
          }
          ${quoteAnimation}
        `}
      >
        <p>
          <SymbolName symbol={props.symbol as AnyFIXME}></SymbolName>
        </p>
        <p
          css={css`
            padding: 0px 5px;
          `}
        >
          {symbolInfo?.close}
        </p>
        <p
          css={css`
            color: ${getQuoteTextColor(quoteChanges.closeChange, isDark)};
          `}
        >
          ({(quoteChanges.closeChange > 0 ? '+' : '') + quoteChanges.closeChange})
        </p>
      </div>
    </Tooltip>
  )
})

const optionRefQuoteBoxCss = css`
  ${flex.h.allCenter};
  min-width: 256px;
  border-radius: 8px;
  p {
    margin: 0;
    ${fontWeight600};
    font-size: 1rem;
  }
`
