import React, { memo } from 'react'
import { useSnapshot } from 'valtio'
import { flex } from '~/modules/AppLayout/FlexGridCss'
import { SignalrOptionRefQuote } from '~/modules/SDK/socket/SignalrOptionRefQuote'
import { useOptionsSourceState } from '~/modules/options/shareContainers/useOptionsSourceState'
import { AtThePriceAvgTimeValueBox } from '~/modules/options/sharedComponents/AtThePriceAvgTimeValueBox'
import { BackTestToggle } from '~/modules/options/sharedComponents/BackTestToggle'
import { SoftDayToSelect } from '~/modules/options/sharedComponents/ComposedDayRangeSelect/DayToSelect'
import { SyntheticFuturesQuote } from '~/modules/options/sharedComponents//SyntheticFuturesQuote'
import { useSyntheticIndexStore } from '~/modules/options/shareContainers/useSyntheticIndexStore'

export const BackTest = memo(function _BackTest() {
  const { state } = useOptionsSourceState.useContainer()
  return (
    <>
      <BackTestToggle />
      {state.isBackTest && <SoftDayToSelect />}
    </>
  )
})

export const RelativeQuotes = memo(function _Quotes() {
  const s = useSnapshot(useSyntheticIndexStore)
  return (
    <>
      <SignalrOptionRefQuote symbol={'TX-1'} />
      <SignalrOptionRefQuote symbol={'TSEA'} />
      <SyntheticFuturesQuote syntheticIndexPrice={s.syntheticIndexPrice} />
      <AtThePriceAvgTimeValueBox atThePriceAvgTimeValue={s.atThePriceAvgTimeValue} />
    </>
  )
})
