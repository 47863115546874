import { css } from '@emotion/react'
import { memo } from 'react'
import useMedia from '~/hooks/useMedia'
import { globalBlue } from '~/modules/AppLayout/Colors'
import { flex } from '~/modules/AppLayout/FlexGridCss'
import { fontWeight600 } from '~/css/font'

/** 價平時間價值 */
export const AtThePriceAvgTimeValueBox = memo<ReactProps<{ atThePriceAvgTimeValue: number }>>(
  function SyntheticFuturesQuote(props) {
    const { isPc } = useMedia()

    return (
      <div
        css={css`
          ${optionRefQuoteBoxCss};
          padding: ${isPc ? '8px 16px' : '8px'};
          border: ${globalBlue.b400} 1px solid;
        `}
      >
        <p
          css={css`
            padding: 0px 4px };
          `}
        >
          價平時間價值
        </p>
        <p>{props.atThePriceAvgTimeValue.toFixed(0)}</p>
      </div>
    )
  },
)

const optionRefQuoteBoxCss = css`
  ${flex.h.allCenter};
  min-width: 256px;
  border-radius: 8px;
  p {
    margin: 0;
    ${fontWeight600};
    font-size: 1rem;
    color: ${globalBlue.b600};
  }
`
